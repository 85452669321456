import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {useWidgetState} from '../../../../hooks/state-provider'
import {useVisibilityStyles} from '../../../../hooks/use-visibility-styles'
import {getRibbonData} from '../../../../selectors/events'
import {shouldShowCountdown} from '../../../../selectors/layout'
import {Countdown} from '../../../countdown'
import {Ribbon} from '../../../ribbon'
import {RsvpButton} from '../../../rsvp-button'
import {Image} from '../../common/image'
import s from './compact-mobile-item.scss'
import {Date} from './date'
import {Location} from './location'
import {Title} from './title'

export const CompactMobileItem = ({event}: {event: ExtendedEvent}) => {
  const {
    allBreakpoints: {isListImageEnabled, isListCountdownEnabled},
  } = useVisibilityStyles()
  const imageVisible = isListImageEnabled()
  const {experiments} = useExperiments()

  const showRibbon = useWidgetState(state => getRibbonData(state, event).visible)
  const showCountdown = shouldShowCountdown(event)

  return (
    <div className={s.root}>
      {imageVisible ? (
        <div className={s.image}>
          <Image event={event} />
        </div>
      ) : null}
      <div
        className={classNames(s.mainSection, {
          [s.overrideMinWidth]: experiments.enabled(ExperimentNames.WidgetPaddingSettings),
        })}
      >
        {showRibbon ? (
          <Ribbon event={event} className={classNames(s.ribbon, {[s.overrideMargin]: !showCountdown})} />
        ) : null}
        {isListCountdownEnabled() && showCountdown && (
          <div className={s.countdown}>
            <Countdown event={event} />
          </div>
        )}
        <div className={s.info}>
          <Title event={event} />
          <div>
            <Date event={event} />
            <Location event={event} />
          </div>
        </div>
        <RsvpButton event={event} minHeight={32} minWidth={100} containerClassName={s.button} />
      </div>
    </div>
  )
}
