import {getFormattedFullLocation} from '@wix/wix-events-commons-statics'
import React from 'react'
import {useVisibilityStyles} from '../../../../../hooks/use-visibility-styles'
import s from './location.scss'

export const Location = ({event}: {event: wix.events.Event}) => {
  const {
    allBreakpoints: {isListLocationVisible},
  } = useVisibilityStyles()

  if (!isListLocationVisible()) {
    return null
  }

  return (
    <div className={s.location} data-hook="location">
      {getFormattedFullLocation(event)}
    </div>
  )
}
