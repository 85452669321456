import {useStyles} from '@wix/tpa-settings/react'
import {WIDGET_TYPE} from '@wix/wix-events-commons-statics'
import React from 'react'
import stylesParams from '../../../../stylesParams'
import {FeaturedCompactMobile} from './featured-compact-mobile'
import {MobileCompactList} from './mobile-compact-list'

export const CompactMobile = () => {
  const {get} = useStyles()
  const featuredWidget = get(stylesParams.widgetType) === WIDGET_TYPE.FEATURED

  return featuredWidget ? <FeaturedCompactMobile /> : <MobileCompactList />
}
