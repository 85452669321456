import {useStyles} from '@wix/tpa-settings/react'
import {ExperimentNames, MOBILE_LIST_LAYOUT_TYPE} from '@wix/wix-events-commons-statics'
import {useExperiments} from '@wix/yoshi-flow-editor'
import React from 'react'
import stylesParams from '../../../../../stylesParams'
import {useWidgetState} from '../../../../hooks/state-provider'
import {getEvents} from '../../../../selectors/events'
import {CardContainer} from '../../../event-container/card-container'
import {ListItemContainer} from '../../../event-container/list-item-container'
import {CompactMobileItem} from '../compact-mobile-item'
import s from './featured-compact-mobile.scss'

export const FeaturedCompactMobile = () => {
  const [featuredEvent] = useWidgetState(getEvents)

  const {experiments} = useExperiments()
  const {get} = useStyles()

  const Container =
    !experiments.enabled(ExperimentNames.ListCardsLayoutType) ||
    get(stylesParams.mobileListLayoutType) === MOBILE_LIST_LAYOUT_TYPE.STRIPS
      ? ListItemContainer
      : CardContainer

  return (
    <div className={s.alignment}>
      <Container event={featuredEvent} applyPadding mobile lastItem showTopDivider>
        {() => <CompactMobileItem event={featuredEvent} />}
      </Container>
    </div>
  )
}
